#newUser{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    text-align: center;
}

#newUser img{
    margin-top: 50px;
}