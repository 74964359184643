#userForm{
    padding: 140px 0 100px;
}

form.user-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 50px;
}

fieldset{
    position: relative;
    width: 380px;
    margin-bottom: 50px;
}
.phone-wrap{
    margin-bottom: 26px;
}

form.user-form input:not(input[type='radio']):not(input[type='submit']){
    width: 380px;
    line-height: 24px;
    padding: 14px 16px;
    border: 1px solid var(--color-input-border);
    border-radius: 4px;
    background-color: var(--color-grey);
}

label{
    position:relative;
    width:fit-content;
    font-size: 16px;
    font-weight: 400;
    color: #7E7E7E;
}

.error-message{
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #7E7E7E;
    padding: 0px 0 0 16px;
}

fieldset.warn > label,
fieldset.warn > .error-message,
fieldset.warn > input{
    transition: all ease-in .3s;
}

fieldset.warn > label,
fieldset.warn > .error-message{
    color: #CB3D40;
}
fieldset.warn > input{
    border-color: #CB3D40!important;
}

.name,
.email,
.phone{
    position: absolute;
    top: 13px;
    left: 10px;
    background: var(--color-grey);
    padding: 2px 4px;
    border-radius: 5px;
    transition: all ease 0.3s;
}
input[type=text]:active ~ .name,
input[type=text]:focus ~ .name,
input[type=text].active ~ .name,
input[type=email]:active ~ .email,
input[type=email]:focus ~ .email,
input[type=email].active ~ .email,
input[type=tel]:active ~ .phone,
input[type=tel]:focus ~ .phone,
input[type=tel].active ~ .phone{
    top: -13px;
    font-size: 12px;
}

.fieldset-select{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
}

.fieldset-select label:first-child{
    margin-bottom: 15px;
}

.fieldset-select label{
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
    margin-bottom: 11px;
}

input[type=radio]{
    position: relative;
    -webkit-appearance: none;
    padding-top: 1px;
}

input[type=radio]:before{
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid var(--color-input-border);
    border-radius: 20px;
    margin-right: 12px;
}

input[type=radio]:after{
    content: '';
    display: block;
    position: absolute;
    top: 6px; left: 5px;
    width: 8px;
    height: 8px;
    background-color: var(--color-input-radio);
    border: 1px solid var(--color-input-radio);
    border-radius: 20px;
    opacity: 0;
    transition: opacity ease-in .3s;
}

input[type=radio]:checked:before{
    border-color: var(--color-input-radio);
}

input[type=radio]:checked:after{
    opacity: 1;
}

.file-uploader-wrap{
    display: flex;
    flex-direction: column;
}

#fileUploader{
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#fileUploader:valid ~ label{
    color: var(--color-text);
}

.file-uploader{
    width: 380px;
    padding: 14px 0;
    border: 1px solid var(--color-input-border);
    border-radius: 4px;
    background-color: var(--color-grey);
}

.file-uploader .uploader-btn{
    color: var(--color-text);
    padding: 14px;
    border: 1px solid var(--color-text);
    border-radius: 4px 0px 0px 4px;
    margin-right: 16px;
}

// .file-uploader .uploader-text{
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
// }

.btn:disabled{
    background: #B4B4B4;
}

@media screen and (max-width: 768px){
    .error-message {
        padding: 1px 0 0 16px;
    }
}

@media screen and (max-width: 560px){
    form.user-form {
        padding-top: 48px;
    }

    fieldset,
    form.user-form input:not(input[type='radio']):not(input[type='submit']),
    .file-uploader{
        width: 328px;
    }
}